//general settings

html{
  scroll-behavior: smooth;
  overflow: scroll;
}
::selection{
 background-color: rgba(30, 127, 136, 1);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #FFFFFF;
}
.container{
  width:100%;
  margin:0 auto;
}

.row::after{
  content: "";
  clear: both;
  display: block;
}

.col{
  float:left;
}

h2, h3{
  font-weight: normal;
}

@font-face {
  font-family: 'muncieregular';
  src: url('./fonts/muncie-webfont.woff2') format('woff2'),
  url('./fonts/muncie-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



body {
  background-color: #0d1a20;
  background-size: cover;
  display: table;
}

button {
  font-size: 24px;
  letter-spacing: 3px;
  background-color: rgba(255, 89, 88, 0.75);
  width: 170px;
  height: 60px;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 720px) {
    font-size: 11px;
    letter-spacing: 2px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    font-size: 11px;
    letter-spacing: 2px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    font-size: 11px;
    letter-spacing: 2px;
  }
}
button:hover {
  transition: 0.5s;
  background-color: rgba(255, 89, 88, 1);
}

.border {
  box-shadow: 0 2px 4px black;
}

a.anchor{
  content:"";
  display:block;
  height:115px; /* fixed header height*/
  margin:-115px 0 0; /* negative fixed header height */
  visibility: hidden;
}

#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 95px; /* Place the button at the bottom of the page */
  right: 95px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  background-color: rgba(30, 127, 136, 0.6);
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  height: 59px;
  width: 59px;
  border-radius: 50% 50%; /* Rounded corners */
  img{
    height: 40px;
    margin-top: -6px;
    margin-left: -2px;
  }
  @media only screen and (max-width: 720px) {
    visibility: hidden;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    visibility: hidden;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    visibility: hidden;
  }
}
#myBtn:hover{
  transition: 0.5s;
  background-color: rgba(30, 127, 136, 1);
}

//header

header {
  background: black;
  width: 100vw;
  height: 115px;
  position: fixed;
  top:0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  z-index: 400;
  @media only screen and (max-width: 720px) {
    height: 60px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    height: 60px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    height: 60px;
  }
  //overflow: hidden;
}
header div.logo {
  background: url("../images/Imprint Media_logo.svg") no-repeat;
  background-size: 55px 55px;
  background-position: center;
  display: inline-block;
  margin-bottom: 7px;
  height: 55px;
  width: 55px;
  margin-left: 82px;
  margin-right: 75px;
  cursor: pointer;
  @-moz-document url-prefix() {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 820px) {
    margin-left: 30px;
    margin-right: 28px;
  }
  @media only screen and (max-width: 720px) {
    margin-left: 15px;
    margin-top: 10px;
    margin-right: 0;
    height: 32px;
    width: 32px;
    background-size: 32px 32px;
    margin-right: auto;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    margin-left: 15px;
    margin-top: 10px;
    margin-right: 0;
    height: 32px;
    width: 32px;
    background-size: 32px 32px;
    margin-right: auto;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    margin-left: 15px;
    margin-top: 10px;
    margin-right: 0;
    height: 32px;
    width: 32px;
    background-size: 32px 32px;
    margin-right: auto;
  }
}
div.logo:hover {
  transition: 0.5s;
  background: url("../images/99d9eefddcc4522a0a28257e5bf39789.png") no-repeat;
  background-size: 55px 55px;
  @media only screen and (max-width: 720px) {
    transition: 0.5s;
    background: url("../images/99d9eefddcc4522a0a28257e5bf39789.png") no-repeat;
    background-size: 32px 32px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    transition: 0.5s;
    background: url("../images/99d9eefddcc4522a0a28257e5bf39789.png") no-repeat;
    background-size: 32px 32px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    transition: 0.5s;
    background: url("../images/99d9eefddcc4522a0a28257e5bf39789.png") no-repeat;
    background-size: 32px 32px;
  }
}

header div.ohir {
  display: inline-block;
  font-size: 80px;
  letter-spacing: 5px;
  font-family: muncieregular;
  margin-top: 5px;
  align-items: center;
  @-moz-document url-prefix() {
    margin-top: 15px;
  }
  @media only screen and (max-width: 720px) {
    position: absolute;
    margin: 5px 30%;
    font-size: 50px;
    width: 67px;
    text-align: center;
    @-moz-document url-prefix() {
      margin-top: 8px;
    }
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    position: absolute;
    margin: 5px 30%;
    font-size: 50px;
    width: 67px;
    text-align: center;
    @-moz-document url-prefix() {
      margin-top: 8px;
    }
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    position: absolute;
    margin: 5px 30%;
    font-size: 50px;
    width: 67px;
    text-align: center;
    @-moz-document url-prefix() {
      margin-top: 8px;
    }
  }
}
header nav.col{
  float: right;
  font-size: 20px;
  font-family: 'PT sans';
  @-moz-document url-prefix() {
    margin-top: 8px;
  }
  //@media only screen and (max-width: 820px) {
  //  font-size: 16px;
  //  margin: 0;
  //  padding: 0;
  //}
  @media only screen and (max-width: 720px) {
    display: none;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {

  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {

  }
}
header a{
  text-decoration: none;
  margin-left: 15px;
  margin-right: 10px;
  cursor: pointer;
}
header a:hover{
  color: rgba(255, 89, 88, 1);
  transition: 0.5s;
}

header button{
  font-size: 20px;
  margin-right: 35px;
  margin-left: 25px;
  margin-top: 15px;
  background-color: black;
  color: #ff5958;
  height: 50px;
  width: 140px;
  border: 2px solid rgba(255, 89, 88, 1);
}
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) { /* STYLES GO HERE */}

header button:hover{
  transition: 0.8s;
  background-color: #ff5958;
  color: #FFFFFF;
}

//main site

@mixin centerer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

main {
  @media only screen and (min-width: 1440px) {
    @include centerer;
  }
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: black;
  width: 1440px;
  padding-top: 115px;
  @media only screen and (max-width: 720px) {
    padding-top: 60px;
    width: 100vw;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    padding-top: 60px;
    width: 100vw;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    padding-top: 60px;
    width: 100vw;
  }
}

//section 1 of the site -- OHIR

main section.OHIR{
  background: url('../images/Ohir_header.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  height: 418px;
  display: flex;
  //flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 720px) {
    background: url('../images/Mobile Page Slices/pexels-photo-287229-(1).png') no-repeat;
    background-size: 100% 200px;
    height: 200px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    background-size: 100% 200px;
    height: 200px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    background-size: 100% 200px;
    height: 200px;
  }
  p{
    font-size: 280px;
    //align-self: center;
    letter-spacing: 5px;
    //margin-left: 30vw;
    //margin-right: 30vw;
    text-shadow: -5px 0 #1E7F88;
    font-family: muncieregular;
    @media only screen and (max-width: 720px) {
      display: none;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      display: none;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      display: none;
    }
  }
}

//section 2 of the site -- ONBOARD

section.ONBOARD {
  display: flex;
  flex-flow: row wrap;
  height: 789px;
  background-image: url("../images/space-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  @media only screen and (max-width: 720px) {
    height: 309px;
    background-size: cover;
    background-position: center;
    justify-content: center;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    height: 309px;
    background-size: cover;
    background-position: center;
    justify-content: center;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    height: 309px;
    background-size: cover;
    background-position: center;
    justify-content: center;
  }
  display: flex;
  div.book{
    background: url("../images/Ohir_front_back.png") no-repeat;
    background-size: 900px 600px;
    background-position: -80px -10px;
    height: 600px;
    width: 650px;
    margin-left: 50px;
    position: relative;
    margin-right: auto;
    display: inline;
    @media only screen and (max-width: 720px) {
      background: url('../images/Mobile Page Slices/Ohir_book-fnb.png') no-repeat;
      position: absolute;
      height: 220px;
      width: 280px;
      background-size: 279px 210px;
      background-position: 0;
      margin: -170px auto;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      position: absolute;
      height: 220px;
      width: 280px;
      background-size: 280px 220px;
      background-position: 0;
      margin: -170px auto;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      position: absolute;
      height: 220px;
      width: 280px;
      background-size: 280px 220px;
      background-position: 0;
      margin: -170px auto;
    }

  }
  div.border{
    margin-top: 160px;
    margin-right: 80px;
    padding: 30px 0 40px 20px;
    background-color: rgba(30, 127, 136, 0.6);
    height: 214px;
    max-width: 585px;
    min-width: 585px;
    margin-left: 40px;
    flex-grow: 1;
    z-index: 1;
    position: relative;
    @media only screen and (max-width: 720px) {
      height: 106px;
      width: 290px;
      max-width: 290;
      min-width: 0;
      padding: 5px 0 5px 13px;
      position: absolute;
      margin: 0;
      margin-top: 60px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      height: 106px;
      width: 290px;
      max-width: 290;
      min-width: 0;
      padding: 5px 0 5px 13px;
      position: absolute;
      margin: 0;
      margin-top: 60px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      height: 106px;
      width: 290px;
      max-width: 290;
      min-width: 0;
      padding: 5px 0 5px 13px;
      position: absolute;
      margin: 0;
      margin-top: 60px;
    }
    p{
      font-family: "PT sans";
      font-size: 24px;
      color: #FFFFFF;
      line-height: 36px;
      @media only screen and (max-width: 720px) {
        font-size:13px;
        letter-spacing: 0;
        line-height: 20px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        font-size:13px;
        letter-spacing: 0;
        line-height: 20px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        font-size:13px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
  button{
    margin-top: 20px;
    width: 153px;
    height: 54px;
    @media only screen and (max-width: 720px) {
      height: 33px;
      width: 87px;
      font-size: 12px;
      letter-spacing: 2px;
      margin-top: 10px;
      margin-left: 96.5px;
      margin-right: 101.5px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      height: 33px;
      width: 87px;
      font-size: 12px;
      letter-spacing: 2px;
      margin-top: 10px;
      margin-left: 96.5px;
      margin-right: 101.5px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      height: 33px;
      width: 87px;
      font-size: 12px;
      letter-spacing: 2px;
      margin-top: 10px;
      margin-left: 96.5px;
      margin-right: 101.5px;
    }
  }
}

//section 3 of the site -- STORY

main section.STORY{
  background-image: url("../images/ae2254c7a20031b453a409bb2edbbbe5.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-background-origin: border;
  @media only screen and (max-width: 720px) {
    background: url("../images/Mobile Page Slices/abstract-art-artistic-247676.png") no-repeat;
    background-position: center;
    height: 149px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    background: url("../images/Mobile Page Slices/abstract-art-artistic-247676.png") no-repeat;
    background-position: center;
    height: 149px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    background: url("../images/Mobile Page Slices/abstract-art-artistic-247676.png") no-repeat;
    background-position: center;
    height: 149px;
  }
  video{
    width: 100%;
    height: 500px;
    z-index: 5;
    display: none;
    object-fit: cover;
    @media only screen and (max-width: 720px) {
      max-height: 150px;
      width: auto;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      max-height: 150px;
      width: auto;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      max-height: 150px;
      width: auto;
    }
  }
  height: 496px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  button{
  width: 484px;
  height: 70px;
    z-index: 7;
    @media only screen and (max-width: 720px) {
      height: 36px;
      width: 250px;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      height: 36px;
      width: 250px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      height: 36px;
      width: 250px;
    }
  }
}

//section 4 of the site -- FRAGMENT

main section.FRAGMENT{
  background: url("../images/space-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 720px) {
    height: 344px;
    background: url("../images/Mobile Page Slices/astronomy-evening-exploration-962095-(1).png") no-repeat;
    background-size: 100% 344px;
    flex-flow: column;
    align-items: center;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    height: 344px;
    background: url("../images/Mobile Page Slices/astronomy-evening-exploration-962095-(1).png") no-repeat;
    background-size: 100% 344px;
    flex-flow: column;
    align-items: center;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    height: 344px;
    background: url("../images/Mobile Page Slices/astronomy-evening-exploration-962095-(1).png") no-repeat;
    background-size: 100% 344px;
    flex-flow: column;
    align-items: center;
  }
  height: 789px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-family: "PT sans";
  div.book{
    background: url("../images/Book_Mockup_6.png") no-repeat;
    height: 580px;
    width: 815px;
    background-size: 850px 634px;
    margin-top: -65px;
    margin-left: -125px;
    z-index: 6;
    @media only screen and (max-width: 720px) {
      height: 150px;
      width: 250px;
      background-size: 250px 150px;
      min-width: 0;
      margin: 0;
      margin-top: -30px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      height: 150px;
      width: 250px;
      background-size: 250px 150px;
      min-width: 0;
      margin: 0;
      margin-top: -30px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      height: 150px;
      width: 250px;
      background-size: 250px 150px;
      min-width: 0;
      margin: 0;
      margin-top: -30px;
    }
  }
  div.col{
    z-index: 6;
    flex-grow: 1;
    max-width: 520px;
    margin-top: 70px;
    margin-left: 40px;
    color: #FFFFFF;
    line-height: 36px;
    margin-right: 30px;
    @media only screen and (max-width: 720px) {
      margin: -10px auto;
      width: 240px;
      display: flex;
      flex-flow: column;
      align-items: center;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      margin: -10px auto;
      width: 240px;
      display: flex;
      flex-flow: column;
      align-items: center;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      margin: -10px auto;
      width: 240px;
      display: flex;
      flex-flow: column;
      align-items: center;
    }
    h3{
      font-size: 30px;
      letter-spacing: 5px;
      @media only screen and (max-width: 720px) {
        font-size: 14px;
        letter-spacing: 2px;
        text-align: center;
      }

      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        font-size: 14px;
        letter-spacing: 2px;
        text-align: center;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        font-size: 14px;
        letter-spacing: 2px;
        text-align: center;
      }
    }
    p{
      margin-top: 3vh;
      font-size: 24px;
      @media only screen and (max-width: 720px) {
        font-size: 13px;
        line-height: 20px;
        margin-top: -11px;
        text-align: center;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        font-size: 13px;
        line-height: 20px;
        margin-top: -11px;
        text-align: center;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        font-size: 13px;
        line-height: 20px;
        margin-top: -11px;
        text-align: center;
      }
    }
    input {
      width: 300px;
      height: 36px;
      color: #0d1a20;
      font-size: 24px;
      margin-top: 3vh;
      border-radius: 5px;
      padding-left: 10px;
      font-family: "PT sans";
      font-size: 24px;
      @media only screen and (max-width: 720px) {
        height: 34px;
        width: 145px;
        font-size: 13px;
        margin-top: 10px;
        margin-left: 45px;
      }

      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        height: 34px;
        width: 145px;
        font-size: 13px;
        margin-top: 10px;
        margin-left: 45px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        height: 34px;
        width: 145px;
        font-size: 13px;
        margin-top: 10px;
        margin-left: 45px;
      }
    }
    button{
      font-size: 20px;
      font-family: "PT sans";
      width: 327px;
      height: 54px;
      margin-top: 25px;
      letter-spacing: 3px;
      @media only screen and (max-width: 720px) {
        width: 174px;
        height: 36px;
        font-size: 12px;
        letter-spacing: 2px;
        margin-top: 10px;
        margin-left: 30px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        width: 174px;
        height: 36px;
        font-size: 12px;
        letter-spacing: 2px;
        margin-top: 10px;
        margin-left: 30px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        width: 174px;
        height: 36px;
        font-size: 12px;
        letter-spacing: 2px;
        margin-top: 10px;
        margin-left: 30px;
      }
    }
  }
  div.AUTHOR{
    width: 50%;
    margin-top: -130px;
    @media only screen and (max-width: 720px) {
      display: none;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      display: none;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      display: none;
    }
    h3{
      font-size: 30px;
      letter-spacing: 5px;
      text-align: center;
    }
    p{
      text-align: center;
      font-size: 24px;
      margin-top: 2.5vh;
    }
  }

}

//section 4.5 MOBILE ONLY -- author

section.mobile{
  display: none;
  @media only screen and (max-width: 720px) {
    display: flex;
    justify-content: center;
    height: 204px;
    background: url("../images/Mobile Page Slices/astronomy-evening-exploration-962095-(1) copy 2.png") no-repeat;
    -webkit-background-size: 100% 204px;
    background-size: 100% 204px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    display: flex;
    justify-content: center;
    height: 204px;
    background: url("../images/Mobile Page Slices/astronomy-evening-exploration-962095-(1) copy 2.png") no-repeat;
    -webkit-background-size: 100% 204px;
    background-size: 100% 204px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    display: flex;
    justify-content: center;
    height: 204px;
    background: url("../images/Mobile Page Slices/astronomy-evening-exploration-962095-(1) copy 2.png") no-repeat;
    -webkit-background-size: 100% 204px;
    background-size: 100% 204px;
  }
  div {
    @media only screen and (max-width: 720px) {
      font-family: "PT sans";
      text-align: center;
      padding-top: 15px;
      width: 305px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      font-family: "PT sans";
      text-align: center;
      padding-top: 15px;
      width: 305px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      font-family: "PT sans";
      text-align: center;
      padding-top: 15px;
      width: 305px;
    }
    p{
      @media only screen and (max-width: 720px) {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0;
        margin-top: 15px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0;
        margin-top: 15px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0;
        margin-top: 15px;
      }
    }
    h3 {
      font-size: 14px;
      letter-spacing: 2px;
    }
  }
}

//section 5 of the page -- SHOP

main section.SHOP{
  background: url("../images/800a32708fa0b963090eedc5984d0b11.jpg") no-repeat;
  height: 634px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  @media only screen and (max-width: 720px) {
    height: 334px;
    flex-flow: column;
    background: url("../images/Mobile Page Slices/f810afc8e915910e56ffa6ca42b76749.png") no-repeat;
    background-size: 100% 334px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    height: 334px;
    flex-flow: column;
    background: url("../images/Mobile Page Slices/f810afc8e915910e56ffa6ca42b76749.png") no-repeat;
    background-size: 100% 334px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    height: 334px;
    flex-flow: column;
    background: url("../images/Mobile Page Slices/f810afc8e915910e56ffa6ca42b76749.png") no-repeat;
    background-size: 100% 334px;
  }
  div.book {
    //display: inline-block;
    height: 634px;
    width: 860px;
    position: relative;
    background: url("../images/Ohir_single_book.png") no-repeat;
    background-size: 655px 650px;
    background-position: 215px;
    position: relative;
    margin-right: auto;
    display: inline-block;
    @media only screen and (max-width: 720px) {
      height: 240px;
      width: 240px;
      background-size: 240px 240px;
      background-position: 0;
      margin: 0;
      margin-top: -120px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      height: 240px;
      width: 240px;
      background-size: 240px 240px;
      background-position: 0;
      margin: 0;
      margin-top: -120px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      height: 240px;
      width: 240px;
      background-size: 240px 240px;
      background-position: 0;
      margin: 0;
      margin-top: -120px;
    }
  }
  div.border{
    height: 325px;
    min-width: 544px;
    max-width: 544px;
    display: flex;
    //flex-grow: 0.5;
    //width: 500px;
    flex-flow: row wrap;
    font-family: "PT sans";
    font-size: 24px;
    line-height: 36px;
    padding: 32px 20px 40px 47px;
    background-color: rgba(30, 127, 136, 0.6);
    margin-right: 40px;
    position: relative;
    //START HERE YO YO
    @media only screen and (max-width: 720px) {
      position: absolute;
      height: 149px;
      width: 290px;
      min-width: 0px;
      font-size: 13px;
      margin: 0;
      margin-top: 80px;
      padding: 0;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      position: absolute;
      height: 149px;
      width: 290px;
      min-width: 0px;
      font-size: 13px;
      margin: 0;
      margin-top: 80px;
      padding: 0;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      position: absolute;
      height: 149px;
      width: 290px;
      min-width: 0px;
      font-size: 13px;
      margin: 0;
      margin-top: 80px;
      padding: 0;
    }
    span{
      flex-basis: 100%;
      @media only screen and (max-width: 720px) {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 12px;
        margin-top: -10px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 12px;
        margin-top: -10px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 12px;
        margin-top: -10px;
      }
    }
    h3{
      flex-basis: 100%;
      letter-spacing: 5px;
      margin-bottom: 14px;
      @media only screen and (max-width: 720px) {
        letter-spacing: 2px;
        margin-left: 22px;
        font-size: 14px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        letter-spacing: 2px;
        margin-left: 22px;
        font-size: 14px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        letter-spacing: 2px;
        margin-left: 22px;
        font-size: 14px;
      }
    }
    h2{
      margin-top: 10px;
      display: inline;
      font-family: muncieregular;
      font-size: 64px;
      line-height: 76px;
      letter-spacing: 5px;
      @media only screen and (max-width: 720px) {
        letter-spacing: 3px;
        font-size: 36px;
        margin-top: 0;
        margin-left: 23px;
        line-height: 24px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        letter-spacing: 3px;
        font-size: 36px;
        margin-top: 0;
        margin-left: 23px;
        line-height: 24px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        letter-spacing: 3px;
        font-size: 36px;
        margin-top: 0;
        margin-left: 23px;
        line-height: 24px;
      }
    }
    button{
      margin-top: 23px;
      margin-left: 20px;
      height: 50px;
      width: 140px;
      @media only screen and (max-width: 720px) {
        height: 33px;
        width: 87px;
        margin: 0;
        margin-left: 15px;
        margin-top: -6px;
        letter-spacing: 2px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        height: 33px;
        width: 87px;
        margin: 0;
        margin-left: 15px;
        margin-top: -6px;
        letter-spacing: 2px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        height: 33px;
        width: 87px;
        margin: 0;
        margin-left: 15px;
        margin-top: -6px;
        letter-spacing: 2px;
      }
    }
  }
}

//section 6 of the page -- BUY FOUR

main section.FOUR{
  background: url("../images/6be9b44d2734ae87b6612b66e1cfab7a.jpg") no-repeat;
  height: 634px;
  background-size: cover;
  background-position: center;
  display: flex;
  //justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  position: relative;
  @media only screen and (max-width: 720px) {
    height: 334px;
    flex-flow: column;
    background: url("../images/Mobile Page Slices/pexels-photo-287229-(1) copy.png") no-repeat;
    background-size: 100% 334px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    height: 334px;
    flex-flow: column;
    background: url("../images/Mobile Page Slices/pexels-photo-287229-(1) copy.png") no-repeat;
    background-size: 100% 334px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    height: 334px;
    flex-flow: column;
    background: url("../images/Mobile Page Slices/pexels-photo-287229-(1) copy.png") no-repeat;
    background-size: 100% 334px;
  }
  div.book {
    background: url("../images/Ohir_set_of_books.png") no-repeat;
    background-size: 1040px 643px;
    order: 2;
    flex-grow: 1;
    height: 700px;
    width: 800px;
    display: inline;
    //margin-right: 40px;
    margin-left: -95px;
    position: relative;
    margin-top: 180px;
    @media only screen and (max-width: 720px) {
      margin: 0;
      height: 200px;
      width: 280px;
      background-size: 280px 200px;
      position: absolute;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      margin: 0;
      height: 200px;
      width: 280px;
      background-size: 280px 200px;
      position: absolute;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      margin: 0;
      height: 200px;
      width: 280px;
      background-size: 280px 200px;
      position: absolute;
    }
  }
  div.border{
    height: 325px;
    min-width: 544px;
    max-width: 544px;
    display: flex;
    //flex-grow: 0.5;
    //width: 500px;
    flex-flow: row wrap;
    font-family: "PT sans";
    font-size: 24px;
    line-height: 36px;
    padding: 32px 20px 40px 47px;
    background-color: rgba(30, 127, 136, 0.6);
    margin-left: 40px;
    position: relative;
    @media only screen and (max-width: 720px) {
      height: 149px;
      width: 290px;
      min-width: 0;
      padding: 0;
      margin: 0;
      margin-top: 150px;
      z-index: 5;
      margin-bottom: 20px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      height: 149px;
      width: 290px;
      min-width: 0;
      padding: 0;
      margin: 0;
      margin-top: 150px;
      z-index: 5;
      margin-bottom: 20px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      height: 149px;
      width: 290px;
      min-width: 0;
      padding: 0;
      margin: 0;
      margin-top: 150px;
      z-index: 5;
      margin-bottom: 20px;
    }
    span{
      flex-basis: 100%;
      @media only screen and (max-width: 720px) {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 12px;
        margin-top: -20px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 12px;
        margin-top: -20px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 20px;
        margin-left: 12px;
        margin-top: -20px;
      }
    }
    h3{
      flex-basis: 100%;
      letter-spacing: 5px;
      @media only screen and (max-width: 720px) {
        letter-spacing: 2px;
        margin-left: 22px;
        font-size: 14px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        letter-spacing: 2px;
        margin-left: 22px;
        font-size: 14px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        letter-spacing: 2px;
        margin-left: 22px;
        font-size: 14px;
      }
    }
    div.priceROW{
      display: flex;
      //justify-content: center;
      align-items: center;
      height: 80px;
      @media only screen and (max-width: 720px) {
        height: 0;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        height: 0;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        height: 0;
      }
    }
    h2{
      display: block;
      margin-top: 40px;
      height: 80px;
      display: table;
      font-family: muncieregular;
      font-size: 64px;
      line-height: 76px;
      letter-spacing: 5px;
      //padding: 0;
      @media only screen and (max-width: 720px) {
        letter-spacing: 3px;
        font-size: 36px;
        margin-top: 55px;
        margin-left: 23px;
        line-height: 24px;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        letter-spacing: 3px;
        font-size: 36px;
        margin-top: 55px;
        margin-left: 23px;
        line-height: 24px;
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        letter-spacing: 3px;
        font-size: 36px;
        margin-top: 55px;
        margin-left: 23px;
        line-height: 24px;
      }
    }
    button{
      margin-bottom: -40px;
      margin-left: 20px;
      height: 54px;
      width: 158px;
      @media only screen and (max-width: 720px) {
        height: 33px;
        width: 87px;
        margin: 0;
        margin-left: 113px;
        //margin-top: -10px;
        letter-spacing: 2px;
        position: absolute;
        @-moz-document url-prefix() {
          margin-top: -8px;
        }
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
      and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
      {
        height: 33px;
        width: 87px;
        margin: 0;
        margin-left: 113px;
        //margin-top: -10px;
        letter-spacing: 2px;
        position: absolute;
        @-moz-document url-prefix() {
          margin-top: -8px;
        }
      }
      @media only screen
      and (min-device-width: 414px)
      and (max-device-width: 736px)
      and (-webkit-min-device-pixel-ratio: 3) {
        height: 33px;
        width: 87px;
        margin: 0;
        margin-left: 113px;
        //margin-top: -10px;
        letter-spacing: 2px;
        position: absolute;
        @-moz-document url-prefix() {
          margin-top: -8px;
        }
      }
    }
  }
}

//footer

footer {
  position: absolute;
  top: 3875px;
  height: 214px;
  width: 100vw;
  min-width: 1440px;
  background: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "PT sans";
  font-size: 20px;
  line-height: 24px;
  a{
    text-decoration: none;
  }
  p.link:hover{
    transition: 0.5s;
    color: #ff5958;
  }
  @media only screen and (max-width: 720px) {
    top: 1934px;
    flex-flow: column;
    justify-content: center;
    height: 244px;
    min-width: 0;
    font-size: 11px;
    line-height: 16px;
    margin: 0;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 23px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    top: 1934px;
    flex-flow: column;
    justify-content: center;
    height: 244px;
    min-width: 0;
    font-size: 11px;
    line-height: 16px;
    margin: 0;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 23px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    top: 1934px;
    flex-flow: column;
    justify-content: center;
    height: 244px;
    min-width: 0;
    font-size: 11px;
    line-height: 16px;
    margin: 0;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 23px;
  }
  .line{
    display: none;
    @media only screen and (max-width: 720px) {
      display: block;
      border-top: 1px solid white;
      width: 52px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      display: block;
      border-top: 1px solid white;
      width: 52px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      display: block;
      border-top: 1px solid white;
      width: 52px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }
  div.logo{
    background: url("../images/Imprint Media_logo.svg") no-repeat;
    background-size: 55px 55px;
    background-position: center;
    margin-bottom: 7px;
    height: 55px;
    width: 55px;
    cursor: pointer;
    @media only screen and (max-width: 720px) {
      display: none;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      display: none;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      display: none;
    }
  }
}

//SLIDER
#carousel {
  position: relative;
  width:100%;
  //margin: 10px auto;
  margin-top: 10px;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 720px) {
    position: absolute;
    margin-top: 220px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    position: absolute;
    margin-top: 220px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    position: absolute;
    margin-top: 220px;
  }
}

#slides {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 250px;
  margin-top: -100px;
  //@media only screen and (max-width: 1200px) {
  //  width: 100vw;
  //}
  @media only screen and (max-width: 720px) {
    margin-top: -60px;
    height: 150px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    margin-top: -60px;
    height: 150px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    margin-top: -60px;
    height: 150px;
  }
}

#slides ul {
  list-style: none;
  width:100%;
  height:250px;
  margin: 0;
  padding: 0;
  position: relative;
}

#slides li {
  width:100%;
  height:250px;
  float:left;
  text-align: center;
  position: relative;
  font-family: "PT sans";
}
/* Styling for prev and next buttons */
.btn-bar{
  position: absolute;
  width: 100%;
  //@media only screen and (max-width: 1200px) {
  //  width: 100%;
  //}
  z-index: 4;
}

#buttons {
  //padding:0 0 5px 0;
}

#buttons a {
  position: absolute;
  text-align:center;
  display:block;
  font-size:50px;
  float:left;
  outline:0;
  margin:-50px 60px;
  color:#FFFFFF;
  text-decoration:none;
  padding:9px;
  width:35px;
}
a#prev{
  cursor: pointer;
  left: 0;
  width: 40px;
  height: 40px;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  transform: rotate(45deg);
  @media only screen and (max-width: 720px) {
    display: none;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    display: none;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    display: none;
  }
}
a#next{
  cursor: pointer;
  right: 0;
  width: 40px;
  height: 40px;
  border-top: 1px solid white;
  border-right: 1px solid white;
  transform: rotate(45deg);
  @media only screen and (max-width: 720px) {
    display: none;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    display: none;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    display: none;
  }
}
a#next:hover {
  transition: 0.3s;
  border-top: 2px solid white;
  border-right: 2px solid white;
  width: 45px;
  height: 45px;
}
a#prev:hover {
  transition: 0.3s;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  width: 45px;
  height: 45px;
}
.authorContainer{
  margin-top: -20px;
  @media only screen and (max-width: 720px) {
    margin-top: -30px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    margin-top: -30px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    margin-top: -30px;
  }
}

//a#prev:hover, a#next:hover {
//  color:#FFF;
//  text-shadow:.5px 0px #b14943;
//  font-size: 60px;
//  transition: 0.3s;
//}

.quote-phrase, .quote-author {
  font-family: "PT sans";
  display: table-cell;
  vertical-align: middle;
  padding: 5px 20px;
  font-family:'PT sans';
}
//.HAL{
//  letter-spacing: 5px;
//  @media only screen and (max-width: 720px) {
//    letter-spacing: 2px;
//    font-size: 12px;
//  }
//  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
//  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
//  {
//    letter-spacing: 2px;
//    font-size: 12px;
//  }
//  @media only screen
//  and (min-device-width: 414px)
//  and (max-device-width: 736px)
//  and (-webkit-min-device-pixel-ratio: 3) {
//    letter-spacing: 2px;
//    font-size: 12px;
//  }
//}

.quote-phrase {
  height: 100px;
  font-size:22px;
  color:#FFF;
  text-shadow:.5px 0px #b14943;
  font-family: "PT sans";
  @media only screen and (max-width: 720px) {
    font-size: 12px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    font-size: 12px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    font-size: 12px;
  }
}

.quote-author {
  font-style:normal;
  font-size:22px;
  color: #FFFFFF;
  letter-spacing: 5px;
  @media only screen and (max-width: 720px) {
    font-size: 11px;
    letter-spacing: 2px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    font-size: 11px;
    letter-spacing: 2px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    font-size: 11px;
    letter-spacing: 2px;
  }
}

.quoteContainer, .authorContainer {
  display: table;
  width: 100%;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
}
.authorContainer{
  @media only screen and (max-width: 720px) {
    margin-top: -35px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    margin-top: -35px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    margin-top: -35px;
  }
}

.counter {
  align-self: center;
  position: absolute;
  margin-top: -40px;
  padding: 50px;
  :last-child{
    margin-right: 0;
  }
  //width: 100%;
}
.dot {
  transition: 1.26s;
  margin-right: 10px;
  height: 10px;
  width: 10px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
  //margin-top: -550px;
  @media only screen and (max-width: 720px) {
    height: 8px;
    width: 8px;
    margin-right: 4px;
    margin-top: 58px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    height: 8px;
    width: 8px;
    margin-right: 4px;
    margin-top: 58px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    height: 8px;
    width: 8px;
    margin-right: 4px;
    margin-top: 58px;
  }
}
.active {
  transition: 1.26s;
  background-color: #FFFFFF;
}

//overlay

section.OVERLAY{
  background-color: rgba(30, 127, 136, 0.9);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 500;
  margin: 0;
  padding: 0;
  //display: none;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-family: "PT sans";
  letter-spacing: 5px;
  @media only screen and (max-width: 720px) {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 30px;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 30px;
  }
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 30px;
  }
  a:hover{
    transition: 0.5s;
  }
  button{
    margin-top: 50px;
    height: 100px;
    width: 300px;
    background-color: rgba(255, 89, 88, 0.75);
    color: #FFFFFF;
    font-size: 40px;
    border: none;
    @media only screen and (max-width: 720px) {
      font-size: 14px;
      height: 40px;
      width: 130px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
    and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
    {
      font-size: 14px;
      height: 40px;
      width: 130px;
    }
    @media only screen
    and (min-device-width: 414px)
    and (max-device-width: 736px)
    and (-webkit-min-device-pixel-ratio: 3) {
      font-size: 14px;
      height: 40px;
      width: 130px;
    }
  }
  button:hover{
      background-color: rgba(255, 89, 88, 1);
      transition: 0s;
  }
}
.hidden{
  visibility: hidden;
}
